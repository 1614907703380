/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Header } from "../../components";

const Layout = ({ children, pageContext }) => {
  const data = useStaticQuery(graphql`
    query IndividualPostBySlug {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <>
      <div className={'container grid-lg'}>
        <Header siteTitle={data.site.siteMetadata.title} siteSubTitle={data.site.siteMetadata.description} />
        <main className={'content-wrapper'}>{children}</main>
        <footer className={'show-social-icons border-style-solid social-icon-style-round show-product-item-nav product-social-sharing'}>
            <div id="social-footer">
              <a href="https://twitter.com/joodle" target="_blank" rel="noopener noreferrer"  className="sr-only social-twitter">Judy Jacob on Twitter</a>
              <a href="http://www.linkedin.com/in/judyjacob" target="_blank" rel="noopener noreferrer"  className="sr-only social-linkedin">Judy Jacob on Linkedin</a>
              <a href="http://instagram.com/joodl" target="_blank" rel="noopener noreferrer"  className="sr-only social-instagram">Judy Jacob on Instagram</a>
              <a href="http://vimeo.com/user907408" target="_blank" rel="noopener noreferrer"  className="sr-only social-vimeo">Judy Jacob on Vimeo</a>
              <a href="http://judyjacob.tumblr.com" target="_blank" rel="noopener noreferrer"  className="sr-only social-tumblr">Judy Jacob on Tumblr</a>
              <a href="mailto:judy@beggarsvelvet.com" target="_blank" rel="noopener noreferrer"  className="sr-only social-email">Judy Jacob Email</a>
            </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
