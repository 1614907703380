import React from 'react'
import classNames from 'classnames'

const Grid = props => {
  return (
    <div className={classNames('columns', props.className)}>
      {props.children}
    </div>
  )
}

export default Grid
