import React from 'react'
import ReactPlayer from 'react-player'
import PropTypes from "prop-types";

const Video = ({videoUrl}) => (
  <div className='player-wrapper'>
    <ReactPlayer
      className='react-player'
      url={videoUrl}
      width='100%'
      height='100%'
    />
  </div>
)

Video.propTypes = {
  videoUrl: PropTypes.string.isRequired,
}

export default Video


