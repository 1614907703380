import { Link } from "gatsby"
import React from "react"

const Menu = () => (
  <section className="navbar-section t-upper f-lato t-small">
    <Link to={'/about'} activeClassName="active" className="btn btn-link">About</Link>
    <Link to={'/work'} activeClassName="active" className="btn btn-link">Work</Link>
    <Link to={'/blog'} activeClassName="active" className="btn btn-link">Blog</Link>
  </section>
)

export default Menu
