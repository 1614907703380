import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Menu } from "../../components"

const Header = ({ siteTitle, siteSubTitle }) => (
  <header id={'header'} className={'ta-c navbar'}>
    <Menu />
    <section id={'site-title'} className={'ta-c'}>
      <Link className={'td-n'} to={'/'}>
        <h1 className={'mb-0 pb-1'}>{siteTitle}</h1>
      </Link>
      <p className={'header-subtitle f-lato t-upper t-space-double mb-0'}>{siteSubTitle}</p>
    </section>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteSubTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  siteSubTitle: ``,
}

export default Header


