import { Link } from "gatsby";
import React from "react";
import Img from "gatsby-image";

const HoverItem = ({img, title, link, className}) => (
  <article className="navbar-section t-upper f-lato t-small hover">
    <Link to={link}>
      <div className="hover__no-hover">{img && <Img className={'spacing-bottom--sm'} fluid={img} />}</div>
      <div className="hover__hover centered">{title}</div>
    </Link>
  </article>
)

export default HoverItem
